import {
    UTMS,
  } from "../constants";
  
  const INIT_STATE = {
    utmMedium: "",
    utmCampaign: "",
    utmTerm: "",
    utmSource: "",
    utmContent: "",
    locale: process.env.REACT_APP_LANGUAGE
  };
  
  const UtmsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case UTMS:

        if (action.payload.utmMedium) state.utmMedium = action.payload.utmMedium;
        if (action.payload.utmCampaign) state.utmCampaign = action.payload.utmCampaign;
        if (action.payload.utmTerm) state.utmTerm = action.payload.utmTerm;
        if (action.payload.utmSource) state.utmSource = action.payload.utmSource;
        if (action.payload.utmContent) state.utmContent = action.payload.utmContent;
        if (action.payload.locale) state.locale = action.payload.locale;
  
        return {
          ...state
        };
      default:
        return state;
    }
  };
  
  export default UtmsReducer;
  