import {
  ADD_PRODUCT,
  ADD_PRODUCT_ERROR,
  EDIT_PRODUCT,
  EDIT_PRODUCT_ERROR,
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_ERROR
} from '../../constants'

const INIT_STATE = {
  errorMessage: '',
  product: []
}

const SalesPriceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      state.product.push(action.payload[0]);
      return {
        ...state,
        errorMessage: 'Erro ao salvar produto!'
      };
    case ADD_PRODUCT_ERROR:
      return {
        ...state,
        errorMessage: 'Erro ao salvar produto!'
      }
    case EDIT_PRODUCT: {
      const product = state.product
      product[action.index] = action.payload
      return {
        ...state,
        product
      }
    }
    case EDIT_PRODUCT_ERROR:
      return {
        ...state,
        errorMessage: 'Atualização do produto com erro!'
      }
    case REMOVE_PRODUCT:
      return {
        ...state,
        product: state.product.filter((x, i) => i !== action.index)
      }
    case REMOVE_PRODUCT_ERROR:
      return {
        ...state,
        errorMessage: 'Erro ao deletar produto!'
      }
    default:
      return state
  }
}

export default SalesPriceReducer
