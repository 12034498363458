import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import RootReducers from './Rootreducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['PricingToolReducer', 'SalesPriceReducer', 'LoginReducer', 'UtmsReducer']
}

const persistedReducer = persistReducer(persistConfig, RootReducers)

export function configureStore(InitialState) {
  const Store = createStore(
    persistedReducer,
    InitialState,
    composeWithDevTools(applyMiddleware(thunk)),
  );

  const persistor = persistStore(Store)
  return {Store, persistor};
}

