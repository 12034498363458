import {
    CREATE_STORE_ERROR
} from "../constants";

const INIT_STATE = {

};

const StoreReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_STORE_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
            };
        case "TOKEN_ERROR":
            return {
                ...state,
                loading: false,
                tokenErrorMessage: action.payload,
            };
        case "PLAN_ERROR":
            return {
                ...state,
                loading: false,
                planErrorMessage: action.payload,
            };
        default:
            return state;
    }
};

export default StoreReducer;