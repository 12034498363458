import { UPDATE_PRICING, UPDATE_PRICING_ERROR } from '../../constants';

const INIT_STATE = {
  errorMessage: '',
  gateway: 0,
  fee: 0,
  platform: 0,
};

const PricingToolReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PRICING:
      return {
        ...state,
        gateway: action.payload.gateway,
        fee: action.payload.fee,
        platform: action.payload.platform,
      };
    case UPDATE_PRICING_ERROR:
      return {
        ...state,
        errorMessage: "Atualização de valores com erro!"
      };
      
    default:
      return state;
  }
};

export default PricingToolReducer;