import { RESET, RESET_ERROR } from '../constants';

const INIT_STATE = {
  errorMessage: '',
  authToken: '',
  userName: ''
};

const ResetPasswordReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET:
      return {
        ...state,
        authToken: action.payload.authToken,
        userName: action.payload.userName
      };
    case RESET_ERROR:
      return {
        ...state,
        errorMessage: "Redefinição de senha inválida!"
      };
       
    default:
      return state;
  }
};

export default ResetPasswordReducer;
