import { combineReducers } from "redux";
import CustomizerReducer from "./customizer/CustomizerReducer";
import notifyReducer from "react-redux-notify";
import LoginReducer from "./login/LoginReducer";
import SignUpReducer from "./signup/SignUpReducer";
import ForgotPasswordReducer from "./forgot-password/ForgotPasswordReducer";
import ResetPasswordReducer from "./reset-password/ResetPasswordReducer";
import SignUpFormReducer from "./signup-form/SignUpFormReducer";
import PricingToolReducer from "./tools/pricing-tool/PricingToolReducer";
import DolarPriceReducer from "./tools/pricing-tool/DolarPriceReducer";
import SalesPriceReducer from "./tools/pricing-tool/SalesPriceReducer";
import EditProfileReducer from "./profile/EditProfileReducer";
import StoreReducer from "./store/Reducer.js";
import UtmsReducer from "./utm/Reducer";

const appReducers = combineReducers({
  CustomizerReducer,
  LoginReducer,
  SignUpReducer,
  ForgotPasswordReducer,
  ResetPasswordReducer,
  SignUpFormReducer,
  PricingToolReducer,
  DolarPriceReducer,
  SalesPriceReducer,
  EditProfileReducer,
  StoreReducer,
  UtmsReducer,
  notifications: notifyReducer,
});

const RootReducers = (state, action) => {
  if (action.type === "LOG_OUT") {
    return appReducers(undefined, action);
  }
  return appReducers(state, action);
};

export default RootReducers;
