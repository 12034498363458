import { FORGOT_PASSWORD, FORGOT_PASSWORD_ERROR } from '../constants';

const INIT_STATE = {
  errorMessage: '',
  forgotPassword: {},
};

const ForgotPasswordReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {...state.forgotPassword, ...action.payload},
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: "Tentativa inválida!"
      };
      
    default:
      return state;
  }
};

export default ForgotPasswordReducer;