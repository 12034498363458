import { SIGN_UP_ERROR, SET_LOADING } from '../constants';

const INIT_STATE = {
  errorMessage: '',
  loading: false,
};

const SignUpFormReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        errorMessage: "ERRO AO VALIDAR!"
      };
   
    default:
      return state;
  }
};

export default SignUpFormReducer;