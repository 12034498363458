// hosts
export const authHost = process.env.REACT_APP_AUTHHOST
export const appHost = process.env.REACT_APP_APPHOST;
export const productLabHost = "https://dropsmart-reports.herokuapp.com";
export const botsmartHost = "https://dropsmart-reports.herokuapp.com";
export const comunidadeHost = process.env.REACT_APP_COMMUNITYHOST;
export const dollarHost = "https://economia.awesomeapi.com.br/json/daily";

// Layout and colors CONSTANTS
export const LIGHT_THEME = "LIGHT_THEME";
export const DARK_THEME = "DARK_THEME";
export const THEME_COLOR = "THEME_COLOR";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const DIRECTION = "DIRECTION";
export const DROPSMART_THEME = "DROPSMART_THEME";
export const BLUE_THEME = "BLUE_THEME";
export const GREEN_THEME = "GREEN_THEME";
export const RED_THEME = "RED_THEME";
export const BLACK_THEME = "BLACK_THEME";
export const PURPLE_THEME = "PURPLE_THEME";
export const INDIGO_THEME = "INDIGO_THEME";
export const ORANGE_THEME = "ORANGE_THEME";

// Login
export const SIGN_IN = "SIGNIN";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const LOG_OUT = "LOG_OUT";

//SignUp

export const SIGN_UP = "SIGNUP";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
export const USER_DATA = "USER_DATA";

// Forgot password

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

// Reset password
export const RESET = "RESET";
export const RESET_ERROR = "RESET_ERROR";

// Edit Profile
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

//Plan Page
export const GET_PLAN = "GET_PLAN";
export const GET_PLAN_ERROR = "GET_PLAN_ERROR";
export const GET_PAYMENT = "GET_PAYMENT";
export const GET_PAYMENT_ERROR = "GET_PAYMENT_ERROR";

//Store Page
export const CREATE_STORE = "STORE";
export const CREATE_STORE_ERROR = "STORE_ERROR";
export const UPDATE_STORE = "STORE";
export const UPDATE_STORE_ERROR = "STORE_ERROR";
export const GET_STORE = "GET_STORE";
export const GET_STORE_ERROR = "GET_STORE_ERROR";

// Courses
export const FETCH_COURSE = "FETCH_COURSE";
export const FETCH_COURSE_ERROR = "FETCH_COURSE_ERROR";
export const FETCH_COURSES = "FETCH_COURSES";
export const FETCH_COURSES_ERROR = "FETCH_COURSES_ERROR";
export const FETCH_MODULE = "FETCH_MODULE";
export const FETCH_MODULE_ERROR = "FETCH_MODULE_ERROR";
export const FINISH_LESSON = "FINISH_LESSON";

//Email Verification
export const EMAIL_VERIFICATION = "EMAIL_VERIFICATION";
export const EMAIL_VERIFICATION_ERROR = "EMAIL_VERIFICATION_ERROR";
//LeadForm
export const SET_LOADING = "SET_LOADING";

//Pricing-tool
export const FETCH_PRICE = "FETCH_PRICE";
export const FETCH_PRICE_ERROR = "FETCH_PRICE_ERROR";
export const UPDATE_PRICING = "UPDATE_PRICE";
export const UPDATE_PRICING_ERROR = "UPDATE_PRICE_ERROR";
export const GET_PRICING = "GET_PRICING";
export const GET_PRICING_ERROR = "GET_PRICING_ERROR";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR";
export const ADD_FAVORITE = "ADD_FAVORITE";
export const ADD_FAVORITE_ERROR = "ADD_FAVORITE_ERROR";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const EDIT_PRODUCT_ERROR = "EDIT_PRODUCT_ERROR";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const REMOVE_PRODUCT_ERROR = "REMOVE_PRODUCT_ERROR";

//PRODUCTS
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_ERROR = "FETCH_PRODUCTS_ERROR";
export const GET_USER_PRODUCTS = "GET_USER_PRODUCTS";
export const GET_USER_PRODUCTS_ERROR = "GET_USER_PRODUCTS_ERROR";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const ADD_PRODUCTS_ERROR = "ADD_PRODUCTS_ERROR";

//UTMS
export const UTMS = "UTMS";