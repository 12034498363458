import { UPDATE_PROFILE, UPDATE_PROFILE_ERROR, GET_PROFILE, GET_PROFILE_ERROR } from '../constants';

const INIT_STATE = {
  errorMessage: '',
  user: {},
};

const EditProfileReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        user: action.payload
      };
      case GET_PROFILE_ERROR:
      return {
        ...state,
        errorMessage: "Erro ao buscar dados do profile!"
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: {...state.user, ...action.payload},
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        errorMessage: "Atualização de cadastro com erro!"
      };
      
    default:
      return state;
  }
};

export default EditProfileReducer;