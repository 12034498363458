import { FETCH_PRICE, FETCH_PRICE_ERROR } from '../../constants'

const INIT_STATE = {
  errorMessage: '',
  valueDollar: 0
}

const DolarPriceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PRICE:
      return {
        ...state,
        valueDollar: action.payload[0]
      }
    case FETCH_PRICE_ERROR:
      return {
        ...state,
        errorMessage: 'Erro ao buscar valores do dolar!'
      }

    default:
      return state
  }
}

export default DolarPriceReducer
