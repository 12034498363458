import { SIGN_UP, SIGN_UP_ERROR } from '../constants';

const INIT_STATE = {
  errorMessage: '',
  authToken: '',
  registered: false,
  userName: ''
};

const SignUpReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_UP:
      return {
        ...state,
        registered: true
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        errorMessage: action.payload.errors[0].message
      };
   
    default:
      return state;
  }
};

export default SignUpReducer;