import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  "pt": {
    translation: {
      errorMessages: {
        "domainError": "Domínio não existe ou já está sendo usado.",
        "tokenError": "Token inválido, verifique o escopos do app.",
        "planError": "Plano Shopify inválido, selecione um plano pago.",
      },
      authentication: {
        login: {
          "email": "E-mail",
          "emailError": "E-mail inválido",
          "password": "Senha",
          "remember": "Lembrar desse dispositivo",
          "forgotPassword": "Esqueceu sua senha?",
          "enter": "Entrar",
          "passwordMessage": "Mínimo de 8 caracteres"
        },
        emailVerification: {
          "hello": "Olá!",
          "redirect": "Você será redirecionado!"
        },
        error: {
          "notFound": "A página não existe!",
          "back": "Voltar"
        },
        passwordResetSent: {
          "title": "Deu certo!",
          "description": "O link para criar uma nova senha já foi enviado para seu email!",
          "back": "Voltar para Login"
        },
        forgotPassword: {
          "title": "Esqueceu sua senha?",
          "description": "Por favor, insira o email associado a sua conta e nós iremos encaminhar um email com o link para você criar uma nova senha.",
          "changePassword": "Alterar a Senha",
          "back": "Voltar para Login"
        },
        resetPassword: {
          "title": "Redefinir sua senha",
          "description": "Para redefinir sua senha, crie uma nova senha de no mínimo 8 caracteres.",
          "insertPassword": "Insira sua nova senha",
          "passwordMessage": "Mínimo de 8 caracteres",
          "confirmPasswordMessage": "A senha nova não confere com a anterior",
          "confirmPassword": "Confirme sua nova senha",
          "redefinePassword": "Redefinir Senha"
        },
        signUp: {
          "welcomeTo": "Bem-vindo(a) à",
          "hasAccount": "Já possui uma conta?",
          "enter": "Entrar",
          "back": "Voltar",
          "next": "Próximo",
          "finish": "Finalizar",
          "confirmPassword": "Confirme a senha",
          "passwordMessage": "Mínimo de 8 caracteres"
        },
        signUpForm: {
          "cta": "QUERO PARTICIPAR",
          "invalidEmail": "Invalid email!",
          "name": "Nome Completo*",
          "email": "Email*",
          "invalidMail": "Invalid E-mail",
          "whatsapp": "Whatsapp com DDD*",
          "privacy": "Ao informar meus dados, eu concordo com a Política de privacidade."
        }
      },
      typ: {
        title: "Obrigado por escolher o {{builderName}}! Estamos empolgados por tê-lo conosco.",
        p1: "Você está a apenas 30 minutos de lançar sua loja pré-construída",
        p2: "Em breve, você receberá as credenciais necessárias para acessar a plataforma por e-mail",
        button: "Entrar",
        getStarted: "Como Começar:",
        point1: "Clique no botão 'Entrar' para acessar a plataforma Shop Build Pro.",
        point2: "Use as credenciais enviadas para o seu endereço de e-mail para fazer login na plataforma.",
        point3: "Você encontrará um guia abrangente passo a passo projetado para guiá-lo no processo de configuração da sua loja. É simples, rápido e destinado a fazer sua loja funcionar em menos de 30 minutos."
      },
      store: {
        customizer: {
          welcome: {
            "nextDays": "Nos próximos",
            "fifteenDays": "30 minutos",
            "youWill": "você irá:",
            "receiveShopifyStore": "🛍 Receber uma loja Shopify",
            "professionalAndReady": "PROFISSIONAL E PRONTA PARA COMEÇAR A VENDER",
            "toHave": "📦 Ter",
            "proovenProducts": "10 PRODUTOS COMPROVADOS E COM ALTO POTENCIAL",
            "choosenCategory": "no nicho que você escolher adicionados a sua loja",
            "winning": "🎁 Ganhar",
            "access": "ACESSOS",
            "allowYouToStepAhead": "que irão te permitir sair na frente da concorrência",
            "free": "💎 Liberar um",
            "exclusiveBonus": "BÔNUS EXCLUSIVO",
            "value": "no valor de $1.500,",
            "forFree": "GRATUITAMENTE",
            "startNow": "Comece agora mesmo, seguindo o passo a passo:",
            "paragraph": "Além disso, nós vamos adicionar 10 produtos testados e comprovados a sua loja, com texto de venda, imagens e páginas prontas para você começar a vender.",
            "followSteps": "👉 Comece agora mesmo. Basta seguir os próximos passos",
            "start": "Começar"
          },
          form: {
            "title": "Bem vindos ao customizador de lojas da {{builderName}}",
            "hello": "Você poderá escolher as cores e imagens enquanto visualiza como ficará sua loja!",
            "startShop": "Para começar a configurar a sua loja, qual o seu email?",
            "pleaseNote": "Use o seu melhor e-mail, você precisará dele para acessar sua loja.",
            "email": "E-mail",
            "name": "Nome",
            "phone": "Whatsapp",
            "invalidEmail": "Email inválido!",
            "start": "Começar"
          },
          offer: {
            "storeAlmostReady": "Sua loja profissional está quase pronta!",
            "instructionsByEmail": "Você receberá instruções por",
            "email": "e-mail",
            "off": "OFF",
            "limitedTime": "Por tempo limitado!",
            "from": "de",
            "for": "por",
            "oneTimePayment": "Pagamento único!",
            "getDeal": "Quero abrir meu negócio",
            "getBestDeal": "Quero abrir meu negócio",
            "winningProducts": "Produtos vencedores",
            "makePayment": "Compre agora",
            "back": "Voltar",
            "termsofuse": "Termos de uso",
          },
          checkout: {
            "makePayment": "Efetuar pagamento",
            "oneTimePayment": "Pagamento único",
            "back": "Voltar",
            "termsofuse": "Termos de uso",
          },
          plan: {
            //"title": "Finalize a criação da sua loja com 80% de desconto",
            "title": "Comece a sua jornada do jeito certo!",
            "text": "Escolha entre começar com 10, 15 ou 20 Produtos!",
            "disclaimer": "*Você irá precisar de uma loja Shopify:",
            "disclaimerHighlight": "Todos os planos incluem o plano Basic da Shopify por R$5,00 além de 3 dias de teste grátis."
            //"text": "",
            //"disclaimer": "",
            //"disclaimerHighlight": "Não feche essa página! Essa oferta está disponível por tempo limitado"
          },
          wizard: {
            back: "Voltar",
            finish: "Finalizar",
            next: "Avançar",
            "category": "Categoria",
            "color": "Cores",
            "banner": "Banner",
            "image": "Imagem",
            "product": "Produtos",
            categories: {
              "unlock": "Libere",
              "chooseCategory": "Escolha a categoria da sua Loja",
              "pet": "Pet",
              "tech": "Tecnologia",
              "beautyAndHealth": "Beleza e bem estar",
              "sportAndFitness": "Esporte e Fitness",
              "kidsAndToys": "Infantil e brinquedos",
              "homeAndKitchen": "Casa e cozinha",
              "general": "Diversos",
              nicheModal: {
                title: "Leve sua loja para o próximo nível e aumente suas vendas com nichos.",
                text: "Clique no botão abaixo para liberar nichos específicos na sua loja. Todos os produtos, banner e cores serão ideias para o nicho que você escolher.",
                cta: "Libere agora"
              }
            },
            colors: {
              "chooseColor": "Escolha uma combinação de cores para a sua loja:"
            },
            banners: {
              "chooseBanner": "Qual será o banner da pagina incial da sua loja?"
            },
            figure: {
              "greatWork": "Sua loja está ficando linda!",
              "chooseFigure": "Escolha a imagem da página inicial da sua loja:"
            },
            products: {
              "productTitle": "Produto",
              "sellingPrice": "Preço de Venda",
              "productAvailable": "Esses são alguns dos produtos disponíveis para você..",
              "chooseProducts": "Você escolhe seus produtos, após o pagamento ser aprovado"
            }
          }
        }
      },
      contact: {
        title: "Precisa de ajuda? Entre em contato",
        text: "Nossa equipe de suporte pode ajudá-lo com qualquer dúvida relacionada à criação da sua loja. Sinta-se à vontade para perguntar qualquer coisa; nosso tempo médio de resposta é de 2 horas durante o horário comercial nos dias úteis",
        name: "Nome",
        email: "E-mail",
        message: "Mensagem",
        button: "Enviar",
        success: "Mensagem enviada com sucesso!",
        successText: "Em breve você receberá um e-mail de resposta. Obrigado!"
      }
    }
  },
  "es": {
    translation: {
      authentication: {
        "login": {
          "email": "Correo electrónico",
          "emailError": "",
          "password": "Contraseña",
          "remember": "Recordar este dispositivo",
          "forgotPassword": "¿Olvidaste tu contraseña?",
          "enter": "Iniciar sesión",
          "passwordMessage": "Mínimo 8 caracteres"
        },
        "emailVerification": {
          "hello": "¡Hola!",
          "redirect": "¡Serás redirigido!"
        },
        "error": {
          "notFound": "¡Página no encontrada!",
          "back": "Regresar"
        },
        "passwordResetSent": {
          "title": "¡Éxito!",
          "description": "¡Se ha enviado un enlace para crear una nueva contraseña a tu correo electrónico!",
          "back": "Regresar al inicio de sesión"
        },
        "forgotPassword": {
          "title": "¿Olvidaste tu contraseña?",
          "description": "Por favor, introduce el correo electrónico asociado con tu cuenta y te enviaremos un correo con el enlace para crear una nueva contraseña.",
          "changePassword": "Cambiar Contraseña",
          "back": "Regresar al inicio de sesión"
        },
        "resetPassword": {
          "title": "Restablece tu contraseña",
          "description": "Para restablecer tu contraseña, crea una nueva contraseña con al menos 8 caracteres.",
          "insertPassword": "Introduce tu nueva contraseña",
          "passwordMessage": "Mínimo 8 caracteres",
          "confirmPasswordMessage": "La nueva contraseña no coincide con la anterior",
          "confirmPassword": "Confirma tu nueva contraseña",
          "redefinePassword": "Restablecer Contraseña"
        },
        "signUp": {
          "welcomeTo": "Bienvenido a",
          "hasAccount": "¿Ya tienes una cuenta?",
          "enter": "Iniciar sesión",
          "back": "Regresar",
          "next": "Siguiente",
          "finish": "Finalizar",
          "confirmPassword": "Confirmar contraseña",
          "passwordMessage": "Mínimo 8 caracteres"
        },
        "signUpForm": {
          "cta": "Obtén tu propia tienda",
          "name": "Nombre completo*",
          "email": "Correo electrónico*",
          "phone": "Teléfono*",
          "invalidPhone": "Teléfono inválido",
          "invalidName": "Nombre inválido",
          "invalidMail": "Correo electrónico inválido",
          "privacy": "Al enviar mi información, acepto la Política de privacidad."
        }
      },
      typ: {
        title: "¡Gracias por elegir {{builderName}}! Estamos emocionados de tenerte con nosotros.",
        p1: "Estás a solo 30 minutos de lanzar tu tienda preconfigurada",
        p2: "Pronto recibirás por correo electrónico las credenciales que necesitas para acceder a la plataforma",
        button: "Iniciar sesión",
        getStarted: "Cómo Empezar:",
        point1: "Haz clic en el botón 'Iniciar sesión' para acceder a la plataforma Shop Build Pro.",
        point2: "Utiliza las credenciales enviadas a tu dirección de correo electrónico para iniciar sesión en la plataforma.",
        point3: "Encontrarás una guía completa paso a paso diseñada para guiarte en el proceso de configuración de tu tienda. Es simple, rápido y está diseñada para poner tu tienda en funcionamiento en menos de 30 minutos."
      },
      store: {
        "customizer": {
          "freePlan": {
            "title": "¡Felicidades, ahora has garantizado tu tienda gratuita!",
            "description": "Mira el video a continuación para ver cómo configurarla y hacerla especial. Es rápido y fácil. ¡Empecemos!",
            "alert": "Eres el emprendedor número {{random}} de 5000 en garantizar un lugar. Si lo dejas ahora, podrías perder tu lugar."
          },
          "offer": {
            "storeAlmostReady": "¡Tu tienda profesional está casi lista!",
            "instructionsByEmail": "Recibirás instrucciones por",
            "email": "correo electrónico",
            "off": "DESCONNECTADO",
            "limitedTime": "¡Por tiempo limitado!",
            "from": "de",
            "for": "para",
            "skipDeal": "Omitir oferta",
            "getDeal": "Obtener oferta",
            "getBestDeal": "Obtener la mejor oferta",
            "oneTimePayment": "Pago único",
            "back": "Regresar",
            "termsofuse": "Términos de uso"
          },
          "plan": {
            "title": "¡Espera! Revisa esta oportunidad única",
            "skipDeal": "Omitir oferta",
            "text": "Antes de disfrutar de tu tienda, adelántate con productos seleccionados de alto rendimiento. Listos para vender ahora. No pierdas la oportunidad de aumentar las ganancias.",
            "disclaimer": "Necesitarás configurar una cuenta de Shopify para alojar tu tienda:",
            "disclaimerHighlight": "Obtendrás una prueba gratuita de 3 días y una tarifa reducida de $1 para los primeros 3 meses de suscripción."
          },
          "checkout": {
            "makePayment": "Comprar ahora",
            "back": "Regresar",
            "termsofuse": "Términos de uso"
          },
          "wizard": {
            "back": "Regresar",
            "finish": "Finalizar",
            "next": "Siguiente",
            "category": "Categoría",
            "color": "Color",
            "banner": "Banner",
            "image": "Imagen",
            "product": "Producto",
            "categories": {
              "unlock": "Desbloquear",
              "chooseCategory": "Elige la categoría de tu tienda",
              "pet": "Mascotas",
              "tech": "Tecnología",
              "beautyAndHealth": "Belleza y Salud",
              "sportAndFitness": "Deportes y Fitness",
              "kidsAndToys": "Niños y Juguetes",
              "homeAndKitchen": "Hogar y Cocina",
              "general": "Miscelánea",
              "nicheModal": {
                "title": "Lleva tu tienda al siguiente nivel e incrementa la conversión con un nicho.",
                "text": "Haz clic en el botón de abajo para mejorar tu tienda a un nicho específico. Todos los productos, banners y colores se alinearán perfectamente con el nicho que elijas.",
                "cta": "Desbloquearlo ahora"
              }
            },
            "colors": {
              "chooseColor": "Elige una combinación de colores para tu tienda:"
            },
            "banners": {
              "chooseBanner": "Elige el fondo para el banner de tu página de inicio:"
            },
            "figure": {
              "greatWork": "¡Está quedando bien!",
              "chooseFigure": "Ahora elige una imagen para el banner de tu página de inicio:"
            },
            "products": {
              "productTitle": "Producto",
              "sellingPrice": "Precio de venta",
              "productAvailable": "Estos son algunos de los productos disponibles para ti..",
              "chooseProducts": "Eliges tus productos después de que se apruebe el pago"
            }
          }
        }
      },
      contact: {
        title: "¿Necesitas ayuda? Contáctanos",
        text: "Nuestro equipo de soporte puede ayudarle con cualquier pregunta relacionada con la creación de su tienda. No dude en preguntar cualquier cosa; nuestro tiempo medio de respuesta es de 2 horas durante el horario laboral en días laborables.",
        name: "Nombre",
        email: "Correo Electrónico",
        message: "Mensaje",
        button: "Enviar",
        success: "Mensaje enviado exitosamente.",
        successText: "Pronto recibirás un correo de respuesta. ¡Gracias!"
      }
    }
  },
  "en": {
    translation: {
      errorMessages: {
        "domainError": "Domain does not exist or is already in use.",
        "tokenError": "Invalid token, check app scopes.",
        "planError": "Invalid Shopify plan. Please select the Shopify or Advanced option to advance"
      },
      authentication: {
        login: {
          "email": "Email",
          "emailError": "Invalid email",
          "password": "Password",
          "remember": "Remember this device",
          "forgotPassword": "Forgot your password?",
          "enter": "Sign in",
          "passwordMessage": "Minimum 8 characters"
        },
        emailVerification: {
          "hello": "Hello!",
          "redirect": "You will be redirected!"
        },
        error: {
          "notFound": "Page not found!",
          "back": "Go Back"
        },
        passwordResetSent: {
          "title": "Success!",
          "description": "A link to create a new password has been sent to your email!",
          "back": "Go back to Login"
        },
        forgotPassword: {
          "title": "Forgot your password?",
          "description": "Please enter the email associated with your account and we will send you an email with the link to create a new password.",
          "changePassword": "Change Password",
          "back": "Go back to Login"
        },
        resetPassword: {
          "title": "Reset your password",
          "description": "To reset your password, create a new password with at least 8 characters.",
          "insertPassword": "Enter your new password",
          "passwordMessage": "Minimum 8 characters",
          "confirmPasswordMessage": "New password does not match the previous one",
          "confirmPassword": "Confirm your new password",
          "redefinePassword": "Reset Password"
        },
        signUp: {
          "welcomeTo": "Welcome to",
          "hasAccount": "Already have an account?",
          "enter": "Sign in",
          "back": "Go back",
          "next": "Next",
          "finish": "Finish",
          "confirmPassword": "Confirm password",
          "passwordMessage": "Minimum 8 characters"
        },
        signUpForm: {
          "cta": "Get your own store",
          "name": "Full Name*",
          "email": "Email*",
          "phone": "Phone*",
          "invalidPhone": "Invalid Phone",
          "invalidName": "Invalid Name",
          "invalidMail": "Invalid E-mail",
          "privacy": "By submitting my information, I agree to the Privacy Policy."
        }
      },
      typ: {
        title: "Thank you for choosing {{builderName}}! We're thrilled to have you with us.",
        p1: "You're just 30 minutes away from launching your pre-built store",
        p2: "You will soon receive the credentials you need to access the platform by e-mail",
        button: "Sign in",
        getStarted: "How to Get Started:",
        point1: "Click on the 'Sign In' button to access the Shop Build Pro platform.",
        point2: "Use the credentials sent to your e-mail address to log in to the platform.",
        point3: "You will find a comprehensive step-by-step guide designed to walk you through the process of setting up your store. It's simple, quick, and meant to get your store up and running in under 30 minutes."
      },
      store: {
        customizer: {
          "form": {
            "title": "Welcome to the Drop Stores builder!",
            "hello": "You are the {{random}}th entrepreneur out of 5000 to guarantee a spot.",
            "startShop": "To start setting up your store, please enter your email address below.",
            "email": "E-mail",
            "name": "Name",
            "phone": "Phone",
            "pleaseNote": "Please note, it is important that you complete all the steps before closing this tab to ensure you don't miss this opportunity.",
            "invalidEmail": "Invalid email!",
            "start": "Start"
          },
          "freePlan": {
            "title": "Congratulations, you have now guaranteed your free store!",
            "description" : "Watch the video below to see how to set it up and make it special. It's quick and easy. Let's get started!",
            "alert" : "You are the {{random}}th entrepreneur out of 5000 to guarantee a spot. If you leave it now, you might lose your spot."
          },
          "offer": {
            "storeAlmostReady": "Your professional store is almost ready!",
            "instructionsByEmail": "You will receive instructions by",
            "email": "email",
            "off": "OFF",
            "limitedTime": "For a limited time!",
            "from": "from",
            "for": "for",
            "skipDeal": "Skip deal",
            "getDeal": "Get deal",
            "getBestDeal": "Get best deal",
            "oneTimePayment": "One-time payment",
            "back": "Back",
            "termsofuse": "Terms of use",
          },
          plan: {
            "title": "Wait! Check this one-time opportunity",
            "skipDeal": "Skip deal",
            "text": "Before enjoying your store, get ahead with hand-picked, top-performing products. Ready to sell now. Don't miss out on increased profits.",
            "disclaimer": "You'll need to set up a Shopify account to host your store:",
            "disclaimerHighlight": "You will get a 3-day free trial and a reduced rate of $1 for the first 3 months of subscription"
          },
          "checkout": {
            "makePayment": "Buy now",
            "back": "Back",
            "termsofuse": "Terms of use",
          },
          "wizard": {
            "back": "Back",
            "finish": "Finish",
            "next": "Next",
            "category": "Category",
            "color": "Color",
            "banner": "Banner",
            "image": "Image",
            "product": "Product",
            categories: {
              "unlock": "Unlock",
              "chooseCategory": "Choose your store's category",
              "pet": "Pet",
              "tech": "Technology",
              "beautyAndHealth": "Beauty and Health",
              "sportAndFitness": "Sports and Fitness",
              "kidsAndToys": "Kids and Toys",
              "homeAndKitchen": "Home and Kitchen",
              "general": "Miscellaneous",
              nicheModal: {
                title: "Take your store to the next level and increase conversion with a niche.",
                text: "Click the button below to upgrade your store to a specific niche. All products, banner and colors will be aligned perfectly with the niche you choose.",
                cta: "Unlock it now"
              }
            },
            colors: {
              "chooseColor": "Choose a color combination for your store:"
            },
            banners: {
              "chooseBanner": "Choose the background for your homepage banner:"
            },
            figure: {
              "greatWork": "It's looking good!",
              "chooseFigure": "Now choose an image for your homepage banner:"
            },
            products: {
              "productTitle": "Product",
              "sellingPrice": "Selling Price",
              "productAvailable": "These are some of the products available for you..",
              "chooseProducts": "You choose your products after the payment is approved"
            }
          }
        }
      },
      contact: {
        title: "Need help? Contact us",
        text: "Our support team can assist you with any questions related to creating your store. Feel free to ask anything; our average response time is within 2 hours during business hours on weekdays",
        name: "Name",
        email: "Email",
        message: "Message",
        button: "Submit",
        success: "Message sent successfully!",
        successText: "You will soon receive a response email. Thank you!"
      }
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'pt-BR',
    lng: process.env.REACT_APP_LOCALE,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
