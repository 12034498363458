import React from 'react';
import { Box } from '@material-ui/core';
import './spinner.css';

let Spinner = () => (
  <svg id="ds-loading" className="ds-loading" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 1000 1000">
    <g className="cls-1">
        <path className="cls-2" d="M714.26,602c78.57,215.84-37.61,327.46-214.71,327.46H365.77c-48.86,0-96.67-35.05-114.47-84L77.39,367.68c-17.79-48.88,4.5-83.93,53.37-83.93H264.53C441.64,283.75,639.06,395.36,714.26,602Z"/>
    </g>
    <g className="cls-3">
        <path className="cls-2" d="M809.22,507C887.78,722.88,771.6,834.49,594.5,834.49H460.72c-48.86,0-96.67-35-114.47-83.94L172.34,272.73c-17.79-48.88,4.5-83.93,53.37-83.93H359.48C536.59,188.8,734,300.41,809.22,507Z"/>
    </g>
    <g className="cls-4">
        <path className="cls-2" d="M904.17,412.08c78.56,215.85-42,320.84-215.13,330.46H554.35c-48.1,0-95.35-38-113.15-86.94L267.29,177.78c-17.79-48.88,4.51-83.93,53.37-83.93H454.43C631.54,93.85,829,205.46,904.17,412.08Z"/>
    </g>
  </svg>
);

if (process.env.REACT_APP_LOADING_GIF_URL) {
  Spinner = () => (
    <Box className="ds-loading">
      <img src={process.env.REACT_APP_LOADING_GIF_URL} alt="loading" style={{width: '80px', height: '80px'}} />
    </Box> 
  )
} 

export default Spinner;